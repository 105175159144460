import THEME from '../theme';
import { aspectRatioLogoAbout, borderRadiusButtons, linkAppleStore, linkPlayStore, footerSocialData } from '../../config/data';

const breakpoint = 1080;
const colors = [THEME.COLORS.PRIMARY_900, THEME.COLORS.PRIMARY_700]; // Define an array of background colors and text colors

//******************************************************************
// To add gradient background to section, add above containerStyles:: 
// gradientColors = {
//     colors: [
//         'rgba(68, 155, 206, 1)',
//         'rgba(19, 63, 112, 1)',
//         'rgba(19, 23, 54, 1)',
//     ],
//     start: { x: 0, y: 0 },
//     end: { x: 1, y: 0 },
// };
// gradientBackgroundStyles = {
//     position: 'absolute',
//     left: 0,
//     right: 0,
//     top: 0,
//     bottom: 0,
// };


// To add image background to section, add above containerStyles: 
// backgroundImage: {
//     mobile: {
//         uri: require("../../../assets/FotoAboutMobile.jpg")
//     },
//     web: {
//         uri: require("../../../assets/FotoAbout.jpg")
//     },
// },


// To add solid color background to section, add in containerStyles: 
// containerStyles = (width) => ({
//     flexBasis: "auto",
//     backgroundColor: "green" // (Optional) The solid color to use as the background
// });

//******************************************************************

const instagramLink = footerSocialData.find(obj => obj.name === "instagram").link;
const instagramName = footerSocialData.find(obj => obj.name === "instagram").profile;
const whatsappLink = footerSocialData.find(obj => obj.name === "whatsapp")?.link;
const whatsappNumber = footerSocialData.find(obj => obj.name === "whatsapp")?.number;


export const clientConfig = [
    {
        name: "yomkids-c1325",
        pages: [
            {
                name: "about",
                sections: [
                    {
                        header: {
                            //add a logo property if there is a logo at header
                            // logo: {
                            //     style: (width) => ({
                            //         aspectRatio: aspectRatioLogoAbout,
                            //         resizeMode: "contain",
                            //         paddingVertical: width < breakpoint ? "1rem" : "1.5rem",
                            //         marginHorizontal: width < breakpoint ? "1rem" : "1.5rem",
                            //         marginTop: "1rem",
                            //     }),
                            //     uri: require('../../../assets/LogoAbout.png'),
                            // },
                            buttonLogin:
                            {
                                style: (width) => ({
                                    padding: "0.3rem",
                                    marginTop: "2rem"
                                    
                                }),
                                textStyle: (width) => ({
                                    color: THEME.COLORS.TEXT_BUTTON,
                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                    fontSize: width < breakpoint ? 14 : 16,
                                    paddingHorizontal: width < breakpoint ? "0rem" : "1rem",
                                    paddingLeft: width < breakpoint ? "0.5rem" : "1rem"
                                }),
                            },
                            buttonApp: {
                                style: (width) => ({
                                    backgroundColor: "#C76E6E",
                                    padding: "0.5rem",
                                    borderRadius: borderRadiusButtons,
                                    paddingVertical: 8,
                                    paddingHorizontal: "1rem",
                                    marginTop: "2rem",
                                }),
                                textStyle: (width) => ({
                                    color: THEME.COLORS.TEXT_BUTTON,
                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                    fontSize: width < breakpoint ? 14 : 16,
                                }),
                            }
                        },
                        columns: [
                            {
                                style: (width) => ({
                                    justifyContent: 'center',
                                    alignItems: width < breakpoint ? 'center' : 'center',
                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                    paddingBottom: width < breakpoint ? "2rem" : "0rem",
                                    width: width < breakpoint ? "100%" : "60%",
                                }),
                                items: [
                                    {
                                        type: 'image',
                                        uri: require('../../../assets/LogoAbout.png'),
                                        aspectRatio: aspectRatioLogoAbout,
                                        styles: (width) => ({
                                            container: {
                                                width: width < breakpoint ? "100%" : "80%",
                                                marginBottom: width < breakpoint ? "4rem" : "0rem",
                                                marginTop: width < breakpoint ? "5rem" : "5rem",
                                                alignItems: width < breakpoint ? "center" : "center",
                                            },
                                            image: {
                                                width: '100%',
                                                resizeMode: "contain",
                                            },
                                        }),
                                    },
                                    {
                                        type: 'multipleTexts',
                                        textAlign: "left",
                                        content: [
                                            {
                                                text: 'Uma jornada de imersão nas práticas de', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 25,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    fontWeight: 500,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'yoga', style: (width) => ({
                                                    fontSize: width < breakpoint ? 29 : 33,
                                                    color: "#C76E6E",
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TITLE
                                                })
                                            },
                                            {
                                                text: 'e', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 25,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    fontWeight: 500,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'meditação', style: (width) => ({
                                                    fontSize: width < breakpoint ? 29 : 33,
                                                    color: "#D8A53F",
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TITLE
                                                })
                                            },
                                            {
                                                text: 'preparadas de um jeito único e especial para as crianças e toda a família. Uma forma divertida, através de histórias e brincadeiras, para envolver os pequenos e ajudá-los a crescer bem, melhorando a concentração, a aprendizagem, o equilíbrio emocional, o sono e muito mais!', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 25,
                                                    fontWeight: 500,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    paddingBottom: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                        ],
                                    },
                                    {
                                        type: 'plansButton',
                                        text: 'Quero participar',
                                        uri: require('../../../assets/Button1.png'),
                                        buttonStyle: (width) => ({
                                            height: '200px',
                                        }),
                                        textStyle: (width) => ({
                                            fontSize: width < breakpoint ? 12 : 16,
                                            fontWeight: "bold",
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            textAlign: width < breakpoint ? "center" : "center",
                                        }),
                                    },
                                ]
                            }
                        ],
                        backgroundImage: {
                            mobile: {
                                uri: require("../../../assets/Background1.png")
                            },
                            web: {
                                uri: require("../../../assets/Background1.png")
                            },
                        },
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: "#efede7"
                        }),
                        contentStyles: (width) => ({
                            alignItems: width < breakpoint ? "center" : "center",
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexDirection: width < breakpoint ? "column" : "row",
                            flexBasis: "auto",
                            paddingHorizontal: width < breakpoint ? "1.5rem" : "3rem",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '90%' : "50%",
                                    alignItems: "center",
                                    order: width < breakpoint ? 2 : 2,
                                }),
                                items: [
                                    {
                                        type: 'image',
                                        uri: require('../../../assets/Foto2.png'),
                                        aspectRatio: 2.5, //padrão da imagem
                                        styles: (width) => ({
                                            container: {
                                                width: "100%",
                                                marginBottom: width < breakpoint ? "0.5rem" : "3rem",
                                                marginTop: width < breakpoint ? "0.5rem" : "3rem",
                                            },
                                            image: {
                                                width: '100%',
                                                resizeMode: "contain",
                                            },
                                        }),
                                    },
                                ]
                            },
                            {
                                style: (width) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "0rem" : "1rem",
                                    order: width < breakpoint ? 1 : 1,
                                    width: width < breakpoint ? "100%" : "50%",
                                }),
                                items: [
                                    {
                                        type: 'multipleTexts',
                                        textAlign: "left",
                                        content: [
                                            {
                                                text: 'Yom Kids é um app encantador que aborda a metodologia', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 25,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    fontWeight: 500,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'Yoga com Histórias,', style: (width) => ({
                                                    fontSize: width < breakpoint ? 25 : 29,
                                                    color: "#D8A53F",
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TITLE
                                                })
                                            },
                                            {
                                                text: 'que busca reconectar a criança, oferecendo a oportunidade de ela vivenciar o seu corpo, proporcionando autonomia no relacionamento consigo, com os outros e com o planeta de forma segura e amorosa!', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 25,
                                                    fontWeight: 500,
                                                    marginBottom: "3rem",
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    paddingBottom: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                                })
                                            },
                                        ],
                                    },
                                ]
                            }
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: "#efede7"
                        }),
                        contentStyles: (width) => ({
                            alignItems: width < breakpoint ? "center" : "center",
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexDirection: width < breakpoint ? "column" : "row",
                            flexBasis: "auto",
                            paddingHorizontal: width < breakpoint ? "1.5rem" : "5rem",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "0rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'As boas histórias falam à alma e trazem ensinamentos transformadores!',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 30 : 32,
                                            color: "#C76E6E",
                                            textAlign: width < breakpoint ? "left" : "center",
                                            width: "100%",
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            marginTop: width < breakpoint ? 10 : 0,
                                            marginBottom: width < breakpoint ? 10 : 30,
                                        })
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: "#efede7"
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                            paddingHorizontal: width < breakpoint ? "1.5rem" : "0rem",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    width: width < breakpoint ? '100%' : "50%",
                                    order: width < breakpoint ? 2 : 1,
                                }),
                                items: [
                                    {
                                        type: 'image',
                                        uri: require('../../../assets/bolaverdecomyom.png'),
                                        aspectRatio: 1, //padrão da imagem
                                        styles: (width) => ({
                                            container: {
                                                width: width < breakpoint ? "100%" : "100%",
                                            },
                                            image: {
                                                width: '100%',
                                                resizeMode: "contain",
                                            },
                                        }),
                                    },
                                ]
                            },
                            {
                                style: (width) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingVertical: width < breakpoint ? "0rem" : "5rem",
                                    paddingHorizontal: width < breakpoint ? "1.5rem" : "5rem",
                                    order: width < breakpoint ? 1 : 2,
                                    width: width < breakpoint ? "100%" : "50%",
                                }),
                                items: [
                                    {
                                        type: 'multipleTexts',
                                        textAlign: "left",
                                        content: [
                                            {
                                                text: 'Nossas', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 25,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'meditações mágicas,', style: (width) => ({
                                                    fontSize: width < breakpoint ? 29 : 33,
                                                    marginBottom: "3rem",
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    paddingBottom: width < breakpoint ? "0.1rem" : "0px",
                                                    paddingHorizontal: width < breakpoint ? "0.1rem" : "0px",
                                                    color: "#ABC5C9",
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TITLE,
                                                })
                                            },
                                            {
                                                text: 'contos fantásticos,', style: (width) => ({
                                                    fontSize: width < breakpoint ? 29 : 33,
                                                    marginBottom: "3rem",
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    paddingBottom: width < breakpoint ? "0.1rem" : "0px",
                                                    paddingHorizontal: width < breakpoint ? "0.1rem" : "0px",
                                                    color: "#C76E6E",
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TITLE,
                                                })
                                            },
                                            {
                                                text: 'afirmações positivas', style: (width) => ({
                                                    fontSize: width < breakpoint ? 29 : 33,
                                                    marginBottom: "3rem",
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    paddingBottom: width < breakpoint ? "0.1rem" : "0px",
                                                    paddingHorizontal: width < breakpoint ? "0.1rem" : "0px",
                                                    color: "#D8A53F",
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TITLE,
                                                })
                                            },
                                            {
                                                text: 'e', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 25,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'momentos de relaxamento', style: (width) => ({
                                                    fontSize: width < breakpoint ? 29 : 33,
                                                    marginBottom: "3rem",
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    paddingBottom: width < breakpoint ? "0.1rem" : "0px",
                                                    paddingHorizontal: width < breakpoint ? "0.1rem" : "0px",
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TITLE,
                                                    color: "#936177",
                                                })
                                            },
                                            {
                                                text: 'ajudarão a reduzir a ansiedade, melhorar o sono, a autoestima e a capacidade de aprendizagem, além de estimular a imaginação: recurso fundamental para que a criança desenvolva a criatividade e encontre soluções frente aos desafios!', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 25,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                        ],
                                    },
                                ]
                            }
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: "#efede7"
                        }),
                        contentStyles: (width) => ({
                            alignItems: width < breakpoint ? "center" : "center",
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexDirection: width < breakpoint ? "column" : "row",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'Assista o vídeo abaixo e descubra os benefícios de fazer parte do mundo Yom!',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 30 : 32,
                                            color: "#D8A53F",
                                            width: width < breakpoint ? "100%" : "70%",
                                            textAlign: width < breakpoint ? "left" : "center",
                                            marginBottom: 35,
                                            marginTop: 50,
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                        })
                                    },
                                    {
                                        type: 'video',
                                        uri: 'https://www.youtube.com/watch?v=m06hP0ZIF1o&ab_channel=YomKids',
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: "#efede7",
                            paddingHorizontal: width < breakpoint ? "1rem" : "10rem",
                            paddingBottom: 60,
                            paddingTop: 10,
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    width: width < breakpoint ? '100%' : "30%",
                                    order: width < breakpoint ? 2 : 2,
                                }),
                                items: [
                                    {
                                        type: 'image',
                                        uri: require('../../../assets/bolaazulcomyom.png'),
                                        aspectRatio: 0.5, //padrão da imagem
                                        styles: (width) => ({
                                            container: {
                                                width: width < breakpoint ? "100%" : "100%",
                                            },
                                            image: {
                                                width: '100%',
                                                resizeMode: "contain",
                                            },
                                        }),
                                    },
                                ]
                            },
                            {
                                style: (width) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "1rem",
                                    order: width < breakpoint ? 1 : 1,
                                    width: width < breakpoint ? "100%" : "70%",
                                    paddingHorizontal: width < breakpoint ? "1.5rem" : "4rem",
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'São pausas tão cheias de fantasia e encantamento que até quem já cresceu vai gostar de aproveitar!  ',
                                        style: (width) => ({
                                            width: width < breakpoint ? "100%" : "70%",
                                            fontSize: width < breakpoint ? 30 : 32,
                                            color: "#C76E6E",
                                            marginTop: width < breakpoint ? 30 : 40,
                                            textAlign: "left",
                                            fontFamily: THEME.FONTFAMILY.TITLE
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Nossas aulas são especialmente preparadas para cuidar integralmente da saúde, estimulando a empatia, a cooperação, a solidariedade, o desenvolvimento de habilidades físicas, motoras e cognitivas, o reconhecimento e gerenciamento de emoções como raiva, medo, tristeza, ansiedade entre outras.',
                                        style: (width) => ({
                                            width: width < breakpoint ? "100%" : "70%",
                                            fontSize: width < breakpoint ? 23 : 25,
                                            paddingTop: width < breakpoint ? "2rem" : "0rem",
                                            marginTop: width < breakpoint ? 0 : 30,
                                            marginBottom: width < breakpoint ? 20 : 0,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            fontWeight: 500,
                                            textAlign: "left",
                                            fontFamily: THEME.FONTFAMILY.TEXTS
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Serão momentos de troca, parceria e muita diversão para toda a família...só não vale ficar de fora!',
                                        style: (width) => ({
                                            width: width < breakpoint ? "100%" : "70%",
                                            fontSize: width < breakpoint ? 23 : 25,
                                            fontWeight: 500,
                                            marginBottom: "3rem",
                                            paddingTop: width < breakpoint ? "2rem" : "3rem",
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "left",
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        })
                                    },
                                ]
                            }
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: "#efede7"
                        }),
                        contentStyles: (width) => ({
                            alignItems: width < breakpoint ? "center" : "center",
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexDirection: width < breakpoint ? "column" : "row",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'Venha praticar com essa turminha!',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 30 : 33,
                                            paddingTop: width < breakpoint ? "2rem" : "0rem",
                                            paddingHorizontal: width < breakpoint ? "0.1rem" : "0px",
                                            color: "#CE805D",
                                            marginTop: width < breakpoint ? 10 : 30,
                                            textAlign: "center",
                                            fontFamily: THEME.FONTFAMILY.TITLE
                                        })
                                    },
                                    {
                                        type: 'plansButton',
                                        text: 'Quero participar',
                                        uri: require('../../../assets/Button1.png'),
                                        buttonStyle: (width) => ({
                                            height: '200px',
                                        }),
                                        textStyle: (width) => ({
                                            fontSize: width < breakpoint ? 12 : 16,
                                            fontWeight: "bold",
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            textAlign: width < breakpoint ? "center" : "center",
                                        }),
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: "#efede7",
                            paddingHorizontal: width < breakpoint ? "1rem" : "10rem",
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '60%' : "30%",
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 1,
                                    backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                                }),
                                items: [
                                    {
                                        type: 'image',
                                        uri: require('../../../assets/Celular.png'),
                                        aspectRatio: 0.6, //padrão da imagem
                                        styles: (width) => ({
                                            container: {
                                                width: width < breakpoint ? "100%" : "100%",
                                                marginBottom: width < breakpoint ? "0.5rem" : "0rem",
                                                marginTop: width < breakpoint ? "0.5rem" : "0rem",
                                            },
                                            image: {
                                                width: '100%',
                                                resizeMode: "contain",
                                            },
                                        }),
                                    },
                                ]
                            },
                            {
                                style: (width) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "0rem" : "1rem",
                                    order: width < breakpoint ? 2 : 2,
                                    width: width < breakpoint ? "100%" : "50%",
                                }),
                                items: [
                                    {
                                        type: 'multipleTexts',
                                        textAlign: "left",
                                        content: [
                                            {
                                                text: 'Conheça o', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 25,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    fontWeight: 500,
                                                    textAlign: "left",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'Yom Kids', style: (width) => ({
                                                    fontSize: width < breakpoint ? 26 : 30,
                                                    color: "#C76E6E",
                                                    textAlign: "left",
                                                    fontFamily: THEME.FONTFAMILY.TITLE
                                                })
                                            },
                                            {
                                                text: 'e ofereça recursos para as crianças serem felizes e estarem bem-preparadas para o hoje e para o futuro!', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 25,
                                                    fontWeight: 500,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    paddingBottom: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "left",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                        ],
                                    },
                                    {
                                        type: 'text',
                                        text: 'Venha se divertir, se aventurar conosco e fazer parte dessa transformação!',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 26 : 30,
                                            fontWeight: 500,
                                            paddingTop: "2rem",
                                            paddingBottom: width < breakpoint ? "0.1rem" : "0px",
                                            color: "#C76E6E",
                                            textAlign: "left",
                                            fontFamily: THEME.FONTFAMILY.TITLE
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Disponível nas lojas de aplicativos!',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 26 : 33,
                                            color: "#000000",
                                            textAlign: width < breakpoint ? "center" : "center",
                                            marginBottom: 30,
                                            marginTop: 30,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        })
                                    },
                                    {
                                        type: 'appleStoreButton',
                                        uri: linkAppleStore,
                                    },
                                    {
                                        type: 'googlePlayButton',
                                        uri: linkPlayStore,
                                    },
                                ]
                            }
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                        }),
                        contentStyles: (width) => ({
                            alignItems: width < breakpoint ? "center" : "center",
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexDirection: width < breakpoint ? "column" : "row",
                            flexBasis: "auto",
                            paddingHorizontal: width < breakpoint ? "1.5rem" : "5rem",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingTop: width < breakpoint ? "1rem" : "5rem",
                                    paddingBottom: width < breakpoint ? "1rem" : "5rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    paddingHorizontal: width < breakpoint ? "1.5rem" : "0rem"
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'Descobrindo a aventura do aplicativo Yom Kids!',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 30 : 33,
                                            color: "#C76E6E",
                                            textAlign: width < breakpoint ? "left" : "left",
                                            marginBottom: 30,
                                            marginTop: width < breakpoint ? 10 : 0,
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                        })
                                    },
                                    {
                                        type: 'imageCards',
                                        arrowBackgroundColor: {
                                            lighter: `${THEME.COLORS.PRIMARY_800}A0`,
                                            darker: THEME.COLORS.PRIMARY_800
                                        },
                                        info: [
                                            {
                                                title: "Yogando",
                                                infos: "Aulas lúdicas de yoga com personagens animados, crianças e famílias, que vão ajudar a criança a prestar atenção em seu próprio corpo, a criar conexões com sua respiração e com seu pensamento, a sentir-se plena de possibilidades e a brilhar! São momentos de diversão, de troca e de inúmeros benefícios para toda a família.",
                                                image: require("../../../assets/FotoCategoria1.jpg"),
                                            },
                                            {
                                                title: "Jornada das emoções",
                                                infos: "Raiva, medo, saudade, tristeza, ansiedade, ciúmes, são algumas das inúmeras e constantes situações que a criança e o adulto enfrentam a cada dia. Aqui você encontrará estratégias para reconhecer e gerenciar bem tudo que está sentindo! Escolhemos diversas estratégias para você reconhecer e gerenciar bem tudo que está sentindo.",
                                                image: require("../../../assets/FotoCategoria2.jpg"),
                                            },
                                            {
                                                title: "Inspirando, expirando,...e se inspirando",
                                                infos: "A respiração é um poderoso instrumento para equilibrar a criança em todos os níveis, desde o físico até o emocional. Respirar bem traz a calma necessária ao autocontrole e promove a energia necessária para aprendizagem. Aqui a criança vai vivenciar técnicas para cuidar de sua respiração inspirando, expirando.... e se inspirando para a vida!",
                                                image: require("../../../assets/FotoCategoria3.jpg"),
                                            },
                                            {
                                                title: "Meditações mágicas",
                                                infos: "Nossas meditações lúdicas são indicadas para todas as idades e vão ajudar a promover a redução de ansiedade, o aumento da resposta imunológica, da autoestima, a melhoria do sono e da capacidade de aprendizagem, além de ajudar a estimular a imaginação: recurso fundamental para que a criança desenvolva a criatividade e encontre soluções frente aos desafios!",
                                                image: require("../../../assets/FotoCategoria4.jpg"),
                                            },
                                            {
                                                title: "Relaxamentos encantados",
                                                infos: "Criar um ritmo mais lento é fundamental em um mundo onde tudo acontece de forma ininterrupta e acelerada. A pausa se apresenta como um fundamental e saudável contraponto.  ¬¬¬¬Relaxar promove ganhos físicos, psíquicos e emocionais, além de melhorar a qualidade do sono. O Yom Kids traz pausas tão cheias de fantasia e encantamento, que até quem já cresceu vai gostar de aproveitar!",
                                                image: require("../../../assets/FotoCategoria5.jpg"),
                                            },
                                            {
                                                title: "Era uma vez ... ainda é e sempre será",
                                                infos: "Ouvir histórias é um momento mágico! É um convite irresistível para ancorar a atenção nos detalhes e no momento presente. Auxilia o desenvolvimento da fala e da língua. Boas histórias falam a linguagem da alma. Seus ensinamentos trazem um jeito gostoso de entender o mundo. Vamos abrir a imaginação e visitar este lugar onde tudo é diferente e encantado. Basta abrir os ouvidos para escutar.",
                                                image: require("../../../assets/FotoCategoria6.jpg"),
                                            },
                                            {
                                                title: "Afirmações positivas para dormir",
                                                infos: "Quando a criança cai no sono, seu cérebro começa a trabalhar com ondas alfas. Segundo alguns neurocientistas esse é um momento extremamente fértil para oferecermos as afirmações positivas: afirmações de amor, coragem, paz, autoestima, tranquilidade, segurança, empatia... e muitas outras ferramentas que vão ajudá-las a estar bem e serem mais felizes!",
                                                image: require("../../../assets/FotoCategoria7.jpg"),
                                            },
                                        ],
                                        cardStyle: (width) => ({
                                            width: "16rem",
                                            height: '100%',
                                            marginHorizontal: "1rem",
                                            backgroundColor: "transparent",
                                        }),
                                        cardCoverStyle: (width) => ({
                                            width: "100%",
                                            height: "9rem",
                                            borderRadius: "12px",
                                        }),
                                        titleStyle: (width) => ({
                                            fontWeight: "bold",
                                            paddingVertical: "0.5rem",
                                            textAlign: "left",
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            fontFamily: THEME.FONTFAMILY.BOLD,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            fontFamily: THEME.FONTFAMILY.SUBTITLE
                                        }),
                                        infoStyle: (width) => ({
                                            textAlign: "left",
                                            fontSize: THEME.FONTSIZE.EXTRASMALL,
                                            fontFamily: THEME.FONTFAMILY.MEDIUM,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            lineHeight: 20,
                                        }),
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: "#efede7",
                            paddingTop: width < breakpoint ? "5rem" : "0rem"
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'mainButton',
                                        text: 'Visualize o aplicativo',
                                        uri: require('../../../assets/Button2.png'),
                                        buttonStyle: (width) => ({
                                            height: '200px',
                                            alignSelf: 'center'
                                        }),
                                        textStyle: (width) => ({
                                            fontSize: width < breakpoint ? 12 : 16,
                                            fontWeight: "bold",
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            textAlign: width < breakpoint ? "center" : "center",
                                        }),
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                items: [
                                    {
                                        type: 'iconCards',
                                        color: THEME.COLORS.PRIMARY_900,
                                        titleStyle: (width) => ({
                                            fontSize: 20,
                                            fontWeight: 'bold',
                                            marginBottom: 5,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        descriptionStyle: (width) => ({
                                            fontSize: 18,
                                            textAlign: "center",
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        cardData: [
                                            {
                                                icon: 'shield-checkmark-outline',
                                                title: 'PAGAMENTO SEGURO',
                                                description: 'Ambiente seguro. Seus dados estão protegidos e sua compra é 100% segura.',
                                            },
                                            {
                                                icon: 'calendar',
                                                title: 'ACESSO IMEDIATO',
                                                description: 'Após o pagamento, você receberá acesso ao conteúdo comprado no aplicativo.',
                                            },
                                            {
                                                icon: 'medal',
                                                title: '7 DIAS DE GARANTIA',
                                                description: 'Você poderá pedir a devolução de 100% do seu dinheiro dentro deste prazo.',
                                            },
                                        ]
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: "#efede7",
                            padding: "3rem",
                        }),
                    },
                    {
                        columns: [
                            {
                                items: [
                                    {
                                        type: 'text',
                                        text: 'Qual o investimento para fazer parte dessa turminha?',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 28 : 50,
                                            color: "#D8A53F",
                                            paddingVertical: width < breakpoint ? '1rem' : '2rem',
                                            paddingHorizontal: width < breakpoint ? '1.5rem' : '3rem',
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            width: "100%",
                                            textAlign: "center",
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Plano mensal ou anual com renovação automática. Pagamento no boleto ou cartão de crédito. Você tem 7 dias grátis para testar junto com as crianças e usufruir dos benefícios das práticas. Opção de fazer pelo celular (yomkids app), computador ou tablet (yomkidsapp.com).',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 16 : 20,
                                            color: "#000000",
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            paddingBottom: 20,
                                            paddingHorizontal: width < breakpoint ? '1.5rem' : '15rem',
                                            width: "100%",
                                            textAlign: "left",
                                        })
                                    },
                                    {
                                        type: 'plansCards',
                                        arrowBackgroundColor: {
                                            lighter: `${THEME.COLORS.PRIMARY_900}A0`,
                                            darker: THEME.COLORS.PRIMARY_900
                                        },
                                        cardStyle: (width, index) => ({
                                            width: width < breakpoint ? width * 0.8 : width * 0.30,
                                            height: "100%",
                                            borderWidth: "4px",
                                            borderStyle: "solid",
                                            borderColor: "#000000",
                                            backgroundColor: "transparent", // Alternate the background color based on the index
                                        }
                                        ),
                                        titleStyle: (width, index) => ({
                                            fontSize: width < breakpoint ? 34 : 40,
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            color: "#C56C6B",
                                        }),
                                        pricingStyle: (width, index) => ({
                                            fontSize: width < breakpoint ? 38 : 50,
                                            textAlign: "left",
                                            color: "#C56C6B",
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                        }),
                                        infoStyle: (width, index) => ({
                                            textAlign: "left",
                                            fontSize: width < breakpoint ? 14 : 16,
                                            color: "#000000", // Alternate the text color based on the index
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        buttonStyle: (width, index) => ({
                                            color: "#C56C6B", // Alternate the text color based on the index
                                            style: {
                                                borderRadius: borderRadiusButtons,
                                                paddingVertical: width < breakpoint ? 14 : 18,
                                            },
                                            titleStyle: {
                                                fontWeight: 'bold',
                                                color: "white", // Alternate the text color based on the index
                                                fontFamily: THEME.FONTFAMILY.TITLE,
                                                fontSize: width < breakpoint ? 16 : 22,
                                            },
                                        }),
                                    },
                                ]
                            },
                        ],
                        backgroundImage: {
                            mobile: {
                                uri: require("../../../assets/Background3.png")
                            },
                            web: {
                                uri: require("../../../assets/Background3.png")
                            },
                        },
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                            paddingVertical: "6rem"
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    paddingTop: width < breakpoint ? "1rem" : "5rem",
                                    paddingBottom: width < breakpoint ? "1rem" : "5rem",
                                    paddingHorizontal: width < breakpoint ? "0.5rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                }),
                                items: [
                                    {
                                        type: 'multipleTexts',
                                        textAlign: "left",
                                        content: [
                                            {
                                                text: 'Você também pode comprar os', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 40,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    paddingBottom: width < breakpoint ? "0.1rem" : "0px",
                                                    paddingHorizontal: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'CONTEÚDOS SEPARADAMENTE', style: (width) => ({
                                                    fontSize: width < breakpoint ? 25 : 45,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.PRIMARY_900,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'para ter os', style: (width) => ({
                                                    fontSize: width < breakpoint ? 23 : 50,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'RESULTADOS', style: (width) => ({
                                                    fontSize: width < breakpoint ? 30 : 50,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.PRIMARY_900,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                                })
                                            },
                                            {
                                                text: 'QUE VOCÊ', style: (width) => ({
                                                    fontSize: width < breakpoint ? 30 : 50,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                                })
                                            },
                                            {
                                                text: 'DESEJA', style: (width) => ({
                                                    fontSize: width < breakpoint ? 30 : 50,
                                                    padding: width < breakpoint ? "0.1rem" : "0px",
                                                    color: THEME.COLORS.PRIMARY_900,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS,
                                                })
                                            },
                                        ],
                                    },
                                    {
                                        type: 'coursesCards',
                                        arrowBackgroundColor: {
                                            lighter: `${THEME.COLORS.PRIMARY_800}A0`,
                                            darker: THEME.COLORS.PRIMARY_800
                                        },
                                        cardStyle: (width) => ({
                                            width: "18rem",
                                            height: '100%',
                                            marginHorizontal: "1rem",
                                            backgroundColor: "transparent",
                                        }),
                                        cardCoverStyle: (width) => ({
                                            width: "100%",
                                            height: '10.12rem',
                                            borderRadius: '12px',
                                        }),
                                        titleStyle: (width) => ({
                                            textAlign: "left",
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            marginVertical: "0.5rem",
                                            fontFamily: THEME.FONTFAMILY.SUBTITLE
                                        }),
                                        priceStyle: (width) => ({
                                            textAlign: "left",
                                            fontSize: 20,
                                            fontWeight: 'bold',
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            paddingBottom: "0.5rem",
                                            fontFamily: THEME.FONTFAMILY.TEXTS
                                        }),
                                        infoStyle: (width) => ({
                                            textAlign: "left",
                                            fontSize: 15,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            marginVertical: "0.5rem",
                                            fontFamily: THEME.FONTFAMILY.TEXTS
                                        }),
                                        buttonStyle: (width) => ({
                                            backgroundColor: THEME.COLORS.PRIMARY_800,
                                            borderRadius: borderRadiusButtons,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        textStyle: (width) => ({
                                            fontSize: width < breakpoint ? 18 : 22,
                                            color: "#FFFFFF",
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '100%' : "50%",
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'image',
                                        uri: require('../../../assets/Foto1.png'),
                                        aspectRatio: 1.4, //padrão da imagem
                                        styles: (width) => ({
                                            container: {
                                                width: width < breakpoint ? "100%" : "100%",
                                                marginBottom: width < breakpoint ? "0.5rem" : "3rem",
                                                marginTop: width < breakpoint ? "0.5rem" : "3rem",
                                            },
                                            image: {
                                                width: '100%',
                                                resizeMode: "contain",
                                            },
                                        }),
                                    },
                                ]
                            },
                            {
                                style: (width) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "0rem" : "1rem",
                                    order: width < breakpoint ? 2 : 1,
                                    width: width < breakpoint ? "100%" : "50%",
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'Rosa Muniz é pedagoga e João Soares, contador de histórias. Se conheceram em 1992 num lugar com crianças afastadas de suas famílias e em situação de vulnerabilidade. Juntos pensaram em como fazer algo para ajudá-las a se sentirem melhor. Aí, pela primeira vez, surgiu a ideia de contar histórias e brincar de yoga com a criançada! E algo incrível aconteceu...',
                                        style: (width) => ({
                                            fontSize: 18,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            fontWeight: 500,
                                            textAlign: "left",
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            marginBottom: width < breakpoint ? 10 : 30,
                                        })
                                    },
                                    {
                                        type: 'multipleTexts',
                                        textAlign: "left",
                                        content: [
                                            {
                                                text: 'Depois de um tempo, perceberam nelas muitas coisas diferentes: menos conflitos, menos desânimo, mais tranquilidade, mais segurança, mais força para lidar com os desafios e muito mais sorrisos.', style: (width) => ({
                                                    fontSize: 18,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    fontWeight: 500,
                                                    textAlign: "left",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                            {
                                                text: 'Assim começou o Yoga com Histórias, uma ferramenta incrível para ajudar a criança a crescer bem com ela mesma e com tudo ao seu redor.', style: (width) => ({
                                                    fontSize: 18,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    textAlign: "left",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                        ],
                                    },
                                    {
                                        type: 'multipleTexts',
                                        textAlign: "left",
                                        content: [
                                            {
                                                text: 'De lá pra cá, aprenderam com as crianças, estudaram, publicaram dezenas de livros, criaram materiais pedagógicos, meditações e formaram (e continuam a formar) inúmeros instrutores pelo país.', style: (width) => ({
                                                    fontSize: 18,
                                                    marginTop: 30,
                                                    paddingTop: width < breakpoint ? "2rem" : "0rem",
                                                    color: THEME.COLORS.TEXT_ABOUT,
                                                    fontWeight: 500,
                                                    textAlign: "center",
                                                    fontFamily: THEME.FONTFAMILY.TEXTS
                                                })
                                            },
                                        ],
                                    },
                                    {
                                        type: 'text',
                                        text: 'Pessoas que ajudam a levar este método para crianças, famílias e escolas, contribuindo para um mundo melhor. Um desses instrutores é o Dudu Toledo, produtor audiovisual, apaixonado por yoga, meditação e por crianças. Juntos produziram a série Yoga com Histórias, exibida pela TV Cultura e pela TV Rá Tim Bum, com indicação a um prêmio internacional!',
                                        style: (width) => ({
                                            fontSize: 18,
                                            marginTop: width < breakpoint ? 10 : 30,
                                            paddingTop: width < breakpoint ? "2rem" : "0rem",
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            fontWeight: 500,
                                            textAlign: "left",
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            marginBottom: width < breakpoint ? 10 : 30,
                                        })
                                    },
                                ]
                            }
                        ],
                        backgroundImage: {
                            mobile: {
                                uri: require("../../../assets/Background2.png")
                            },
                            web: {
                                uri: require("../../../assets/Background2.png")
                            },
                        },
                        containerStyles: (width) => ({
                            backgroundColor: "#efede7",
                            flexBasis: "auto",
                            paddingVertical: width < breakpoint ? "0.5rem" : "2rem",
                            justifyContent: "center"
                        }),
                        contentStyles: (width) => ({
                            alignItems: width < breakpoint ? "center" : "center",
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexDirection: width < breakpoint ? "column" : "row",
                            flexBasis: "auto",
                            paddingHorizontal: width < breakpoint ? "1.5rem" : "5rem",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "4rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'Empresas, instituições e escolas, converse com a gente, montaremos um pacote de assinaturas especialmente para você proporcionar saúde integral para seus colaboradores.',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 25 : 37,
                                            color: "#D8A53F",
                                            width: "100%",
                                            textAlign: "center",
                                            marginTop: width < breakpoint ? 30 : 50,
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            padding: "10px",
                                        })
                                    },
                                    // {
                                    //     type: 'text',
                                    //     text: 'Conversem com a gente! Montaremos um pacote de assinaturas especialmente para vocês proporcionarem saúde integral para seus colaboradores!',
                                    //     style: (width) => ({
                                    //         fontSize: 18,
                                    //         color: THEME.COLORS.TEXT_ABOUT,
                                    //         width: width < breakpoint ? "100%" : "60%",
                                    //         textAlign: width < breakpoint ? "left" : "center",
                                    //         marginBottom: 20,
                                    //         marginTop: 5,
                                    //         fontFamily: THEME.FONTFAMILY.TEXTS,
                                    //         padding: "10px",
                                    //     })
                                    // },
                                    {
                                        type: 'iconCards',
                                        color: "#D8A53F",
                                        titleStyle: (width) => ({
                                            fontSize: 20,
                                            fontWeight: 'bold',
                                            marginBottom: 5,
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            textAlign: "center",
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        descriptionStyle: (width) => ({
                                            fontSize: 16,
                                            textAlign: "center",
                                            color: THEME.COLORS.TEXT_ABOUT,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        cardData: [
                                            // {
                                            //     icon: 'logo-whatsapp',
                                            //     title: 'Nosso WhatsApp',
                                            //     description: whatsappNumber,
                                            //     uri: whatsappLink,
                                            // },
                                            {
                                                icon: 'logo-instagram',
                                                title: 'Instagram',
                                                description: instagramName,
                                                uri: instagramLink,
                                            },
                                            {
                                                icon: 'mail',
                                                title: 'E-mail',
                                                description: 'yomkidsapp@gmail.com',
                                                uri: 'mailto: yomkidsapp@gmail.com'
                                            },
                                        ],
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'Perguntas frequentes',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 25 : 37,
                                            color: "#D8A53F",
                                            textAlign: "center",
                                            textDecorationLine: "underline",
                                            textDecorationColor: "#D8A53F",
                                            marginBottom: 8,
                                            marginTop: 50,
                                            fontWeight: 'bold',
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            padding: "10px",
                                        })
                                    },
                                    {
                                        type: 'FAQ',
                                        questionStyle: (width, expandedId, question) => ({
                                            flex: 1,
                                            fontSize: width < breakpoint ? 16 : 20,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            color: expandedId === question.id ? "#D8A53F" : THEME.COLORS.TEXT_ABOUT
                                        }),
                                        answerStyle: (width) => ({
                                            fontSize: width < breakpoint ? 16 : 20,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            color: THEME.COLORS.TEXT_ABOUT
                                        }),
                                        containerStyle: (width) => ({
                                            backgroundColor: 'transparent',
                                            elevation: 5,
                                            margin: 10,
                                            overflow: 'hidden',
                                            borderBottomWidth: 1.5,
                                            borderBottomColor: THEME.COLORS.TEXT_ABOUT,
                                        }),
                                        questions: linkPlayStore !== null && linkAppleStore !== null ? [
                                            {
                                                id: 1,
                                                question: 'Tem aplicativo exclusivo?',
                                                answer: 'Tem sim! Você pode baixar o aplicativo nas lojas Apple Store ou Google Play em seu celular.',
                                            },
                                            {
                                                id: 2,
                                                question: 'Como faço para comprar um produto no aplicativo?',
                                                answer: 'Você compra e gerencia suas compras pela versão web do aplicativo, através desse link que você está.',
                                            },
                                            {
                                                id: 3,
                                                question: 'Como faço para acessar os conteúdos?',
                                                answer: 'Você pode acessar o conteúdo por aqui ou pelo aplicativo baixado nas lojas, basta clicar no botão “Visualizar Conteúdo”.',
                                            },
                                            {
                                                id: 4,
                                                question: 'Quais são as formas de pagamento aceitas?',
                                                answer: 'As opções de pagamento são cartão de crédito ou boleto.',
                                            },
                                            {
                                                id: 5,
                                                question: 'É preciso ter cadastro para ter acesso aos conteúdos gratuitos do aplicativo?',
                                                answer: 'Não é necessário, os conteúdos gratuitos estão abertos para todos visualizarem! Basta você acessar a opção “Visualizar Conteúdo” no aplicativo que você baixou ou acessar por aqui.',
                                            },
                                            {
                                                id: 6,
                                                question: 'Após eu finalizar o pagamento, irei receber algum e-mail de confirmação?',
                                                answer: 'Sim! Você receberá um e-mail de boas-vindas com suas credenciais de login e um e-mail de confirmação do pagamento.',
                                            },
                                            {
                                                id: 7,
                                                question: 'Como faço para me cadastrar no aplicativo? ',
                                                answer: 'O cadastro é feito somente para as pessoas que querem ter acesso aos conteúdos pagos. Após você escolher o produto que deseja comprar, você fará um cadastro e será redirecionado para a página de pagamento.',
                                            },
                                            {
                                                id: 8,
                                                question: 'Consigo cancelar a qualquer momento?',
                                                answer: 'Somente planos mensais podem ser cancelados a qualquer momento pelo aplicativo. Caso você tenha um plano de maior recorrência, como o anual, o cancelamento deve ser solicitado formalmente.',
                                            },
                                            {
                                                id: 9,
                                                question: 'Tive um problema com o pagamento, posso alterar a forma de pagamento ou os dados do cartão?',
                                                answer: 'Claro. Se algum problema acontecer com o pagamento, você será informado por e-mail e poderá alterar os dados por aqui, acessando "Meu perfil" no ícone superior esquerdo e clicando no botão "Editar meu plano".',
                                            },
                                        ] : [
                                            {
                                                id: 2,
                                                question: 'Como faço para comprar um produto no aplicativo?',
                                                answer: 'Você compra e gerencia suas compras pela versão web do aplicativo, através desse link que você está.',
                                            },
                                            {
                                                id: 3,
                                                question: 'Como faço para acessar os conteúdos?',
                                                answer: 'Você pode acessar o conteúdo por aqui, basta clicar no botão “Visualizar Conteúdo”.',
                                            },
                                            {
                                                id: 4,
                                                question: 'Quais são as formas de pagamento aceitas?',
                                                answer: 'As opções de pagamento são cartão de crédito ou boleto.',
                                            },
                                            {
                                                id: 5,
                                                question: 'É preciso ter cadastro para ter acesso aos conteúdos gratuitos do aplicativo?',
                                                answer: 'Não é necessário, os conteúdos gratuitos estão abertos para todos visualizarem! Basta você acessar a opção “Visualizar Conteúdo” e acessar os conteúdos desbloqueados.',
                                            },
                                            {
                                                id: 6,
                                                question: 'Após eu finalizar o pagamento, irei receber algum e-mail de confirmação?',
                                                answer: 'Sim! Você receberá um e-mail de boas-vindas com suas credenciais de login e um e-mail de confirmação do pagamento.',
                                            },
                                            {
                                                id: 7,
                                                question: 'Como faço para me cadastrar no aplicativo? ',
                                                answer: 'O cadastro é feito somente para as pessoas que querem ter acesso aos conteúdos pagos. Após você clicar em assinar o plano de assinatura, você fará um cadastro e será redirecionado para a página de pagamento.',
                                            },
                                            {
                                                id: 8,
                                                question: 'Consigo cancelar a qualquer momento?',
                                                answer: 'Somente planos mensais podem ser cancelados a qualquer momento pelo aplicativo. Caso você tenha um plano de maior recorrência (trimestral, semestral ou anual), o cancelamento deve ser solicitado formalmente.',
                                            },
                                            {
                                                id: 9,
                                                question: 'Tive um problema com o pagamento, posso alterar a forma de pagamento ou os dados do cartão?',
                                                answer: 'Claro. Se algum problema acontecer com o pagamento, você será informado por e-mail e poderá alterar os dados por aqui, acessando "Meu perfil" no ícone superior esquerdo e clicando no botão "Editar meu plano".',
                                            },
                                        ]
                                    }
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: "#efede7",
                            paddingHorizontal: width < breakpoint ? "2rem" : "10rem",
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },
                    // {
                    //     columns: [
                    //         {
                    //             style: (width, columnWidth) => ({
                    //                 justifyContent: 'space-between',
                    //                 alignItems: 'center',
                    //                 paddingVertical: width < breakpoint ? "2rem" : "5rem",
                    //                 width: width < breakpoint ? '100%' : columnWidth,
                    //                 alignItems: "center",
                    //             }),
                    //             items: [
                    //                 {
                    //                     type: 'text',
                    //                     text: 'DEPOIMENTOS',
                    //                     style: (width) => ({
                    //                         fontSize: width < breakpoint ? 26 : 38,
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         textAlign: "center",
                    //                         marginBottom: 20,
                    //                         fontFamily: THEME.FONTFAMILY.SUBTITLE,
                    //                     })
                    //                 },
                    //                 {
                    //                     type: 'testimonials',
                    //                     arrowBackgroundColor: {
                    //                         lighter: `${THEME.COLORS.PRIMARY_800}A0`,
                    //                         darker: THEME.COLORS.PRIMARY_800
                    //                     },
                    //                     info: [
                    //                         {
                    //                             title: "~ John",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque. Aut numquam corporis non iste assumend."',
                    //                             //image: require("../../../assets/Testimonial1.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                         {
                    //                             title: "~ Kamesh",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                    //                             //image: require("../../../assets/Testimonial2.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                         {
                    //                             title: "~ Nobita",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque. Aut numquam corporis non iste assumend. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                    //                             //image: require("../../../assets/Testimonial3.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                         {
                    //                             title: "~ Juliet",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                    //                             //image: require("../../../assets/Testimonial4.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                         {
                    //                             title: "~ Peter",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque. Aut numquam corporis non iste assumend. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque."',
                    //                             //image: require("../../../assets/Testimonial5.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                         {
                    //                             title: "~ Lia",
                    //                             infos: '"Lorem ipsum dolor sit amet. Qui harum quos est illum quasi et itaque veritatis et error repellat sit quam cumque. Aut numquam corporis non iste assumend."',
                    //                             //image: require("../../../assets/Testimonial6.jpg"), //add image if the testimonial is an image
                    //                         },
                    //                     ],
                    //                     cardStyle: (width) => ({
                    //                         width: "20rem",
                    //                         height: '100%',
                    //                         marginHorizontal: "2rem",
                    //                         backgroundColor: "transparent",
                    //                     }),
                    //                     titleStyle: (width) => ({
                    //                         paddingVertical: "1rem",
                    //                         textAlign: "center",
                    //                         fontSize: THEME.FONTSIZE.STANDARD,
                    //                         color: THEME.COLORS.PRIMARY_900,
                    //                         fontFamily: THEME.FONTFAMILY.SUBTITLE
                    //                     }),
                    //                     infoStyle: (width) => ({
                    //                         textAlign: "center",
                    //                         padding: "0.5rem",
                    //                         fontSize: THEME.FONTSIZE.SMALL,
                    //                         color: THEME.COLORS.TEXT_ABOUT,
                    //                         fontFamily: THEME.FONTFAMILY.TEXTS
                    //                     }),
                    //                 },
                    //             ]
                    //         },
                    //     ],
                    //     containerStyles: (width) => ({
                    //         flexBasis: "auto",
                    //         backgroundColor: THEME.COLORS.BACKGROUND_ABOUT
                    //     }),
                    //     contentStyles: (width) => ({
                    //         justifyContent: width < breakpoint ? "center" : "center",
                    //         flexBasis: "auto",
                    //     }),
                    // },
                    {
                        columns: [
                            {
                                style: (width, columnWidth) => ({
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: width < breakpoint ? "1rem" : "2rem",
                                    width: width < breakpoint ? '100%' : columnWidth,
                                    alignItems: "center",
                                    order: width < breakpoint ? 1 : 2,
                                }),
                                items: [
                                    {
                                        type: 'text',
                                        text: 'Vamos juntos nessa jornada!',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 16 : 28,
                                            color: "white",
                                            textAlign: "center",
                                            marginTop: 40,
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            paddingHorizontal: width < breakpoint ? "0.5rem" : "2rem",
                                        })
                                    },
                                    {
                                        type: 'plansButton',
                                        text: 'Quero participar',
                                        uri: require('../../../assets/Button1.png'),
                                        buttonStyle: (width) => ({
                                            height: '200px',
                                        }),
                                        textStyle: (width) => ({
                                            fontSize: width < breakpoint ? 12 : 16,
                                            fontWeight: "bold",
                                            color: THEME.COLORS.TEXT_BUTTON,
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            textAlign: width < breakpoint ? "center" : "center",
                                        }),
                                    },
                                ]
                            },
                        ],
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: "#ABC5C9"
                        }),
                        contentStyles: (width) => ({
                            justifyContent: width < breakpoint ? "center" : "center",
                            flexBasis: "auto",
                        }),
                    },

                    // Add more sections for this client here
                ],
            },
            {
                name: "plans",
                sections: [
                    {
                        columns: [
                            {
                                items: [
                                    {
                                        type: 'text',
                                        text: 'Planos de Assinatura',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 28 : 50,
                                            fontWeight: 'bold',
                                            color: "#D8A53F",
                                            paddingVertical: width < breakpoint ? '2rem' : '2rem',
                                            paddingHorizontal: width < breakpoint ? '1rem' : '3rem',
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            width: "100%",
                                            textAlign: "center",
                                        })
                                    },
                                    {
                                        type: 'text',
                                        text: 'Plano mensal ou anual com renovação automática. Pagamento no boleto ou cartão de crédito. Você tem 7 dias grátis para testar junto com as crianças e usufruir dos benefícios das práticas. Opção de fazer pelo celular ou tablet (Yomkids app disponível para download na Apple Store e Google Play) ou computador (www.yomkids.com).',
                                        style: (width) => ({
                                            fontSize: width < breakpoint ? 14 : 20,
                                            color: "#000000",
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                            paddingBottom: 20,
                                            paddingHorizontal: width < breakpoint ? '1rem' : '15rem',
                                            width: "100%",
                                            textAlign: "left",
                                        })
                                    },
                                    {
                                        type: 'plansCards',
                                        arrowBackgroundColor: {
                                            lighter: `${THEME.COLORS.PRIMARY_900}A0`,
                                            darker: THEME.COLORS.PRIMARY_900
                                        },
                                        cardStyle: (width, index) => ({
                                            width: width < breakpoint ? width * 0.8 : width * 0.30,
                                            height: "100%",
                                            borderWidth: "4px",
                                            borderStyle: "solid",
                                            borderColor: "#000000",
                                            backgroundColor: "transparent", // Alternate the background color based on the index
                                        }
                                        ),
                                        titleStyle: (width, index) => ({
                                            fontSize: width < breakpoint ? 34 : 40,
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                            textAlign: "left",
                                            fontWeight: "bold",
                                            color: "#C56C6B",
                                        }),
                                        pricingStyle: (width, index) => ({
                                            fontSize: width < breakpoint ? 38 : 50,
                                            textAlign: "left",
                                            color: "#C56C6B",
                                            fontFamily: THEME.FONTFAMILY.TITLE,
                                        }),
                                        infoStyle: (width, index) => ({
                                            textAlign: "left",
                                            fontSize: width < breakpoint ? 14 : 16,
                                            color: "#000000", // Alternate the text color based on the index
                                            fontFamily: THEME.FONTFAMILY.TEXTS,
                                        }),
                                        buttonStyle: (width, index) => ({
                                            color: "#C56C6B", // Alternate the text color based on the index
                                            style: {
                                                borderRadius: borderRadiusButtons,
                                                paddingVertical: width < breakpoint ? 14 : 18,
                                            },
                                            titleStyle: {
                                                fontWeight: 'bold',
                                                color: "white", // Alternate the text color based on the index
                                                fontFamily: THEME.FONTFAMILY.TITLE,
                                                fontSize: width < breakpoint ? 16 : 22,
                                            },
                                        }),
                                    },
                                ]
                            },
                        ],
                        backgroundImage: {
                            mobile: {
                                uri: require("../../../assets/Background3.png")
                            },
                            web: {
                                uri: require("../../../assets/Background3.png")
                            },
                        },
                        containerStyles: (width) => ({
                            flexBasis: "auto",
                            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                            paddingVertical: "2rem"
                        }),
                    },
                ],
            },
            // add more pages as needed
        ],
    },
    // add more clients as needed
];
